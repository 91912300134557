<template>
    <div class="custom-select" :tabindex="tabindex" @blur="open = false">
        <div class="items top" :class="{ selectHide: !open || position!=='top' }">
            <div
                v-for="(option, i) of options"
                :key="i"
                @click="selected = option; open = false; $emit('send', option); ">
                {{ option }}
            </div>
        </div>
        <div class="selected" :class="{ open: open }" @click="open = !open">
            {{ selected }}
        </div>
        <div class="items" :class="{ selectHide: !open || position!=='bottom' }">
            <div
                v-for="(option, i) of options"
                :key="i"
                @click="selected = option; open = false; $emit('send', option); ">
                {{ option }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true,
        },
        default: {
            type: String,
            required: false,
            default: null,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
        position: {
            type: String,
            required: false,
            default: 'bottom',
        }
    },
    data() {
        return {
            selected: this.default
                ? this.default
                : this.options.length > 0
                    ? this.options[0]
                    : null,
            open: false,
        };
    },
    mounted() {
        this.$emit("send", this.selected);
    },
    computed: {
        userStyle() {
            return {}
        }
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/vars";

.custom-select {
    position: relative;
    width: 200px;
    height: 45px;
    text-align: left;
    outline: none;
    line-height: 40px;
    font-size: 0.9em;

    font-family: $main-font;
}

.custom-select .selected {
    /*background-image: url(../assets/img/ServerChoozeBg.jpg);*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
    background-color: #28272b;
    border-left: 5px solid #4f48b5;
    border-radius: 6px;
    color: #fff;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
}

.custom-select .selected.open {
    border-radius: 6px;
}

.custom-select .selected:after {
    position: absolute;
    content: "";
    top: 19px;
    right: 1em;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: #fff transparent transparent transparent;
}

.custom-select .items {
    margin-top: 5px;
    color: #fff;
    border-radius: 6px;
    overflow: hidden;

    /*border-right: 1px solid #ad8225;*/
    /*border-left: 1px solid #ad8225;*/
    /*border-bottom: 1px solid #ad8225;*/
    position: absolute;
    background-color: #28272b;
    left: 0;
    right: 0;
    z-index: 1;
}

.custom-select .top {
    bottom: 45px;
}

.custom-select .items div {
    color: #fff;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
}

.custom-select .items div:hover {
    background-color: #4f48b5;
}

.selectHide {
    display: none;
}
</style>
