<template>
    <div class="rules">
        <div class="content">
            <transition name="fade" mode="out-in">
                <div class="c">
                    <div class="c-info-block">
                        <h1 class="c-title">Расписание регенерации</h1>
                        <p class="c-info-text">
                            На большинстве наших серверов нет вайпов, и на некоторых из них проводится регенерация
                            <br>
                            <br>
                            Здесь выводится информация о ближайшей регенерации сервера со списком регионов, которые
                            будут удалены.
                            <br>
                            Чтобы избежать удаления региона, овнеру необходимо поддерживать свой онлайн на сервере.
                            <br>
                            <br>
                            <span>Подробнее о том, что такое регенерация, можно <a title="Регенерация мира"
                                                                                   class="c-info-text-link"
                                                                                   href="https://wiki.greatray.ru/Wiki/Регенерация">прочитать на Wiki</a></span>
                        </p>
                        <div class="regenbox">
                            <CustomSelect v-bind:options="getServers()" default="HiTech" @send="changeServer($event)"/>
                            <div class="preloader-container" v-if="regens[serverName] === undefined"/>
                            <div class="flexible-block" :key="regenKey">
                                <p class="c-info-text"
                                   v-if="regens[serverName] !== undefined && regens[serverName] !== null">
                                    Следующая регенерация:
                                    {{ datetimeNormalizer(regens[serverName].task.start_process * 1000, true) }} -
                                    {{ datetimeNormalizer(regens[serverName].task.end_process * 1000, true) }}
                                    <br><br>
                                    Будут удалены регионы игроков, не заходивших с
                                    {{ datetimeNormalizer(regens[serverName].task.start_waiting * 1000, true) }}
                                </p>
                                <p class="c-info-text" v-if="regens[serverName] === null">
                                    По техническим причинам информация временно не отображается
                                </p>
                                <table class="balanceHistoryTable"
                                       v-if="regens[serverName] !== undefined && regens[serverName] !== null">
                                    <colgroup>
                                        <col/>
                                        <col/>
                                        <col/>
                                    </colgroup>

                                    <thead
                                        style="margin-bottom: 10px;border-top: 1px solid rgba(128, 128, 128, 0.29);">
                                    <tr>
                                        <th class="info-table-th">Мир</th>
                                        <th class="info-table-th">Название региона</th>
                                        <th class="info-table-th">Овнеры региона</th>
                                        <th class="info-table-th">Последняя активность овнеров</th>
                                    </tr>
                                    </thead>

                                    <tbody style="margin-top: 10px;">
                                    <tr v-for="region in regens[serverName].regions">
                                        <td class="info-table-td">
                                            {{ region.world }}
                                        </td>
                                        <td class="info-table-td">
                                            {{ region.name }}
                                        </td>
                                        <td class="info-table-td">
                                            {{ getOwners(region.owners) }}
                                        </td>
                                        <td class="info-table-td">
                                            {{ getReasons(region.reasons) }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

import {TimeFormatterMixin} from "@/mixins/TimeFormatterMixin";
import CustomSelect from "@/components/elements/donat/CustomSelect";
import {PreloaderMixin} from "@/mixins/PreloaderMixin";

export default {
    name: "Regen",
    components: {CustomSelect},
    mixins: [TimeFormatterMixin, PreloaderMixin],

    metaInfo: {
        title: 'GreatRay',
        titleTemplate: '%s: Расписание регенерации',
    },
    data() {
        return {
            regens: {},
            serverName: 'HiTech',
            regenKey: 1,
            preloaderContainer: '.preloader-container',
        }
    },
    methods: {
        changeServer(server) {
            this.serverName = server
            if (server !== 'HiTech')
                requestAnimationFrame(this.getRegen)
        },
        getRegen() {
            if (this.regens[this.serverName] === undefined) {
                this.preloaderStartLk(this.preloaderContainer, '#1c1c1d')
                require('axios').get(`/api/regenerator/${this.serverName}`).then(
                    data => {
                        if (data.data[0] !== undefined) {
                            this.regens[this.serverName] = data.data[0]
                        } else {
                            this.regens[this.serverName] = null
                        }
                        this.preloaderStop(this.preloaderContainer)
                        this.regenKey++
                    }
                ).catch(err => {
                    console.log(err)
                })
            }
        },
        getReasons(reasons) {
            let max_time = 0
            reasons.forEach(r => {
                if (r.includes('inactive')) {
                    max_time = Math.max(max_time, r.split("inactive: ").at(-1).split(", ").at(-1) * 1000)
                }
                if (r.includes('force delete by member name')) {
                    max_time = -1
                }
            })
            if (max_time > 0)
                return this.datetimeNormalizer(max_time);
            if (max_time === -1)
                return "Принудительное удаление"
            return "Неизвестно"
        },
        getServers() {
            // TODO получать с бэкенда
            return ['HiTech', 'GalaxyTech', 'GTNH-2022', 'Asgard', 'Elysium']
        },
        getOwners(owners) {
            let res = []
            owners.forEach(owner => res.push(owner.name))
            return res.join(', ')
        },

    },
    mounted() {
        this.getRegen()
    }

}

</script>

<style scoped lang="scss">

@import "src/assets/scss/animations";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";

.preloader-container {
    padding: 70px 300px;
}

.regenbox {
    background: #1c1c1d;
    padding: 20px;
    width: 100%;
    border-radius: 20px;
}

.content {
    @include flexible();
    width: 100vw;

    .c {
        @include simple-ctrr();
    }
}

@include ct-n-rp-transitions();
@include info-table-with-paginator();


</style>
